<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
      v-loading="loading"
    >
      <div slot="title" style="color: #409eff">商务绩效调整变更记录</div>
      <div name="content" class="content">
        <el-descriptions
          style="margin-bottom: 10px"
          title="商务基础信息"
          direction="vertical"
          :column="5"
          border
        >
          <el-descriptions-item label="商务名称" :span="2">
            {{ form.businessName || '' }}
          </el-descriptions-item>
          <el-descriptions-item label="商务类型" :span="1">
            {{ form.businessType | dict(dictData.businessType) }}
          </el-descriptions-item>
          <el-descriptions-item label="项目来源" :span="1">
            {{ form.projectSource | dict(dictData.projectSource) }}
          </el-descriptions-item>
          <el-descriptions-item label="业务种类" :span="1">
            {{ form.businessLines | dict(dictData.businessLines) }}
          </el-descriptions-item>

          <el-descriptions-item label="业主单位" :span="3">
            {{ form.ownerUnit || '' }}
          </el-descriptions-item>
          <el-descriptions-item label="项目承接公司" :span="1">
            {{ form.companyType | dict(dictData.companyType) }}
          </el-descriptions-item>
          <el-descriptions-item label="实际商务负责人" :span="1">
            {{ form.principalUserName }}
          </el-descriptions-item>
        </el-descriptions>

        <div class="content-maincon">
          <div class="content-left">
            <div class="content-text" style="color: blue">更改前</div>
            <el-descriptions direction="vertical" :column="5" border>
              <template slot="title">
                <span style="font-size: 18px; color: black; font-weight: 700">商务资金结算</span>
              </template>
              <template slot="extra">
                <span style="font-size: 17px; color: gray">单位：元</span>
              </template>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('ourContractAmount')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                label="我方合同金额"
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <span class="content-left-row-content">
                  {{ form.oldData.ourContractAmount | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('taxesFee') ? { 'background-color': '#bff0de' } : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>税费总计</div>
                  <div style="font-size: 12px; color: red; margin-top: 5px">
                    包含增值税、附加税、印花税
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.oldData.taxesFee | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('tenderFee')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                label="招投标费用"
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <span class="content-left-row-content">
                  {{ form.oldData.tenderFee | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('exteriorBusinessCosts')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                label="预计外部协作资金"
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <span class="content-left-row-content">
                  {{ form.oldData.exteriorBusinessCosts | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('actualExteriorBusinessCosts')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                label="实际外部协作资金"
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <span class="content-left-row-content">
                  {{ form.oldData.actualExteriorBusinessCosts | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('predictDisposableProjectExpense')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="2"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>预计可支配金额</div>
                  <div style="font-size: 12px; color: red; margin-top: 5px">
                    预计可支配金额 = 我方合同金额 - 税费 - 招投标费用 - 预计外部协作资金
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.oldData.predictDisposableProjectExpense | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('receivedAmount')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>到款金额</div>
                </template>
                <span class="content-left-row-content">
                  {{ form.oldData.receivedAmount | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('productionCost')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>内部生产资金</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      内部生产资金 = 预计可支配金额 X 生产资金比例
                    </span>
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.oldData.productionCost | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('projectOutsourceByBusiness')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>外协生产资金</div>
                </template>
                <span class="content-left-row-content">
                  {{ form.oldData.projectOutsourceByBusiness | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('interiorBusinessPerformance')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="2"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>结算可支配资金</div>
                  <div style="font-size: 12px; color: red; margin-top: 5px">
                    结算可支配资金 = （预计可支配金额 - 内部生产资金 - 外协生产资金）X 25%
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.oldData.interiorBusinessPerformance | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('businessDeptPerformance')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>商务部管理资金</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      商务部管理资金 = 结算可支配资金 X 20%
                    </span>
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.oldData.businessDeptPerformance | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('businessTeamPerformance')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>商务团队费用</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      商务团队费用 = 结算可支配资金 X 80%
                    </span>
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.oldData.businessTeamPerformance | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('actualPerformance')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label"> 实际绩效金额 </template>
                <span class="content-left-row-content">
                  {{ form.oldData.actualPerformance | applyAmount }}
                </span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div>
            <div style="height: 60px"></div>
            <div class="vertical-dashed-line"></div>
          </div>
          <div class="content-right" style="color: red">
            <div class="content-right-view">
              <div class="content-text" style="font-wei">更改后</div>
            </div>
            <el-descriptions direction="vertical" :column="5" border>
              <template slot="title">
                <span style="font-size: 18px; color: black; font-weight: 700">商务资金结算</span>
              </template>
              <template slot="extra">
                <span style="font-size: 17px; color: gray">单位：元</span>
              </template>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('ourContractAmount')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                label="我方合同金额"
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <span class="content-left-row-content">
                  {{ form.newData.ourContractAmount | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('taxesFee') ? { 'background-color': '#eef9b2' } : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>税费总计</div>
                  <div style="font-size: 12px; color: red; margin-top: 5px">
                    包含增值税、附加税、印花税
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.newData.taxesFee | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('tenderFee')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                label="招投标费用"
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <span class="content-left-row-content">
                  {{ form.newData.tenderFee | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('exteriorBusinessCosts')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                label="预计外部协作资金"
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <span class="content-left-row-content">
                  {{ form.newData.exteriorBusinessCosts | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('actualExteriorBusinessCosts')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                label="实际外部协作资金"
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <span class="content-left-row-content">
                  {{ form.newData.actualExteriorBusinessCosts | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('predictDisposableProjectExpense')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="2"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>预计可支配金额</div>
                  <div style="font-size: 12px; color: red; margin-top: 5px">
                    预计可支配金额 = 我方合同金额 - 税费 - 招投标费用 - 预计外部协作资金
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.newData.predictDisposableProjectExpense | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('receivedAmount')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>到款金额</div>
                </template>
                <span class="content-left-row-content">
                  {{ form.newData.receivedAmount | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('productionCost')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>内部生产资金</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      内部生产资金 = 预计可支配金额 X 生产资金比例
                    </span>
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.newData.productionCost | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('projectOutsourceByBusiness')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>外协生产资金</div>
                </template>
                <span class="content-left-row-content">
                  {{ form.newData.projectOutsourceByBusiness | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('interiorBusinessPerformance')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="2"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>结算可支配资金</div>
                  <div style="font-size: 12px; color: red; margin-top: 5px">
                    结算可支配资金 = （预计可支配金额 - 内部生产资金 - 外协生产资金）X 25%
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.newData.interiorBusinessPerformance | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('businessDeptPerformance')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>商务部管理资金</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      商务部管理资金 = 结算可支配资金 X 20%
                    </span>
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.newData.businessDeptPerformance | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('businessTeamPerformance')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label">
                  <div>商务团队费用</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      商务团队费用 = 结算可支配资金 X 80%
                    </span>
                  </div>
                </template>
                <span class="content-left-row-content">
                  {{ form.newData.businessTeamPerformance | applyAmount }}</span
                >
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('actualPerformance')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
                label-class-name="my-label"
                content-class-name="my-content"
              >
                <template slot="label"> 实际绩效金额 </template>
                <span class="content-left-row-content">
                  {{ form.newData.actualPerformance | applyAmount }}
                </span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>

      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="primary"
          style="min-width: 120px; margin: 0 25px"
          plain
          :loading="loading"
          @click="cancellationFn"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 85,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      dictData: {
        projectSource: [], //项目来源 PROJECT_SOURCE
        businessLines: [], //业务种类 BUSINESS_LINES
        businessType: [],
        companyType: [],
      },
      form: {
        changeFieldList: [], //变更字段
        newData: {}, //	新数据
        oldData: {}, //	旧数据
      },
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.getData()
        }
        this.isDialog = newVal
      },
    },
  },
  computed: {
    ...mapState({}),
  },
  filters: {
    dateFormat(value) {
      if (value) {
        value = Number(value)
        return new Date(value).format('yyyy-MM-dd')
      } else {
        return ''
      }
    },
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {
    this.getType()
  },
  mounted() {},
  methods: {
    getData() {
      this.loading = true
      this.$api.businessManage
        .selectLogById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getType() {
      //业务种类 BUSINESS_LINES
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      //项目来源 PROJECT_SOURCE
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.content-right-view {
  display: flex;
  justify-content: space-between;
}
.content-text {
  font-weight: 900;
  font-size: 20px;
  text-align: left;
}
.content-maincon {
  display: flex;
  justify-content: center;
  width: 100%;
  .content-left {
    width: 49%;
  }
  .content-right {
    width: 49%;
  }
  .vertical-dashed-line {
    margin: 0 5px;
    border-left: 3px dashed #ccc; /* 虚线的样式和颜色 */
    height: 88%; /* 虚线的高度 */
  }
  .content-left-row-content {
    line-height: 40px;
  }
}
/deep/.my-label {
  width: 20%;
}
/deep/.my-content {
  width: 20%;
}

.content {
  max-height: calc(90vh - 164px);
  overflow: auto;
}
</style>
